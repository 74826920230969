/* Your own custom utilities */

.has-text-align-left {

  text-align: left
}

.has-text-align-center {

  text-align: center
}

.has-text-align-right {

  text-align: right
}

.has-large-font-size {

  font-size: 2.25rem;

  line-height: 2.5rem
}

.alignfull {

  position: relative;

  width: 100vw;

  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  right: 50%
}

.wp-block-image img {

  margin-top: 0.5rem;

  max-width: 100%
}
.wp-block-image.aligncenter {

  text-align: center
}
.wp-block-image.alignfull img,
.wp-block-image.alignwide img {

  width: 100%
}
.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image .aligncenter,
.wp-block-image.is-resized {

  margin-left: 0px;

  margin-right: 0px;

  display: table
}
.wp-block-image .alignleft > figcaption,
.wp-block-image .alignright > figcaption,
.wp-block-image .aligncenter > figcaption,
.wp-block-image.is-resized > figcaption {

  display: table-caption;

  caption-side: bottom
}

.wp-block-image .alignleft {

  float: left;

  margin-right: 1rem
}
.wp-block-image .alignright {

  float: right;

  margin-left: 1rem
}
.wp-block-image .aligncenter {

  margin: auto
}

.wp-block-button a,
.wp-block-file a.wp-block-file__button {

  --tw-bg-opacity: 1;

  background-color: rgb(59 130 246 / var(--tw-bg-opacity));

  padding-top: 0.5rem;

  padding-bottom: 0.5rem;

  padding-left: 1rem;

  padding-right: 1rem;

  --tw-text-opacity: 1;

  color: rgb(255 255 255 / var(--tw-text-opacity));

  -webkit-text-decoration-line: none;

          text-decoration-line: none
}

.wp-block-button a:hover,
.wp-block-file a.wp-block-file__button:hover {

  cursor: pointer;

  --tw-bg-opacity: 1;

  background-color: rgb(96 165 250 / var(--tw-bg-opacity))
}

.wp-block-file a:first-of-type {

  margin-right: 1rem
}

.wp-block-cover {

  display: flex;

  flex-wrap: wrap;

  align-items: center;

  justify-content: center;

  overflow: hidden;

  background-size: cover;

  background-position: center;

  min-height: 430px
}

.wp-block-verse {

  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.wp-block-media-text {

  display: grid;

  grid-template-columns: repeat(2, minmax(0, 1fr));

  gap: 1rem
}

@font-face {
font-family: '__EDNimpkishRegular_3a05c4';
src: url(/_next/static/media/f6acdeb289afb162-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__EDNimpkishRegular_Fallback_3a05c4';src: local("Arial");ascent-override: 92.39%;descent-override: 23.10%;line-gap-override: 15.40%;size-adjust: 103.90%
}.__className_3a05c4 {font-family: '__EDNimpkishRegular_3a05c4', '__EDNimpkishRegular_Fallback_3a05c4';font-weight: 400;font-style: normal
}

@font-face {
font-family: '__copernicusGalaxyMedium_c6e2ad';
src: url(/_next/static/media/3b1642d9d1baae52-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__copernicusGalaxyMedium_c6e2ad';
src: url(/_next/static/media/3b1642d9d1baae52-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: '__copernicusGalaxyMedium_Fallback_c6e2ad';src: local("Arial");ascent-override: 90.02%;descent-override: 38.58%;line-gap-override: 0.00%;size-adjust: 116.64%
}.__className_c6e2ad {font-family: '__copernicusGalaxyMedium_c6e2ad', '__copernicusGalaxyMedium_Fallback_c6e2ad'
}

@font-face {
font-family: '__copernicusGalaxyBook_41382a';
src: url(/_next/static/media/46a32a7704a757c0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__copernicusGalaxyBook_41382a';
src: url(/_next/static/media/46a32a7704a757c0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: '__copernicusGalaxyBook_Fallback_41382a';src: local("Arial");ascent-override: 91.25%;descent-override: 39.11%;line-gap-override: 0.00%;size-adjust: 115.07%
}.__className_41382a {font-family: '__copernicusGalaxyBook_41382a', '__copernicusGalaxyBook_Fallback_41382a'
}

@font-face {
font-family: '__GillSansMedium_c2426f';
src: url(/_next/static/media/a276abb8a14e69e9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__GillSansMedium_Fallback_c2426f';src: local("Arial");ascent-override: 74.45%;descent-override: 34.71%;line-gap-override: 21.83%;size-adjust: 91.61%
}.__className_c2426f {font-family: '__GillSansMedium_c2426f', '__GillSansMedium_Fallback_c2426f';font-weight: 400;font-style: normal
}

@font-face {
font-family: '__GillSansMediumItalic_21eea2';
src: url(/_next/static/media/8bc5d504a88e81fa-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__GillSansMediumItalic_Fallback_21eea2';src: local("Arial");ascent-override: 80.13%;descent-override: 37.36%;line-gap-override: 23.50%;size-adjust: 85.11%
}.__className_21eea2 {font-family: '__GillSansMediumItalic_21eea2', '__GillSansMediumItalic_Fallback_21eea2';font-weight: 400;font-style: normal
}

@font-face {
font-family: '__GillSansBold_96f318';
src: url(/_next/static/media/8c96d3b18033165c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__GillSansBold_Fallback_96f318';src: local("Arial");ascent-override: 64.37%;descent-override: 30.01%;line-gap-override: 18.88%;size-adjust: 105.95%
}.__className_96f318 {font-family: '__GillSansBold_96f318', '__GillSansBold_Fallback_96f318';font-weight: 700;font-style: normal
}

